<template>
  <v-card
    v-if="pSelectedHost"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-data-table
      class="host-invitations-table"
      :headers="headers"
      :items="displayHostInvitations"
      :loading="currentPageLoading"
      :server-items-length="totalCount"
      @update:options="paginate($event)"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Host Invitations</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="canUserInviteMembers"
            color="primary"
            class="invite-btn"
            :disabled="currentPageLoading"
            @click="openEditHostInvitationDialog({})"
          >
            Invite
          </v-btn>
          <HostInvitationDialog ref="hostInvitationDialog" />
          <ConfirmationDialog ref="deleteDialog" />
        </v-toolbar>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.host`]="{ item }">
        {{item.host.name}}
      </template>

      <template v-slot:[`item.roles`]="{ item }">
        <HostRoles :host-roles="item.roles" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center" v-if="!item.hostMembership">
          <v-icon
            medium
            class="mr-2 invitation-edit-btn"
            :disabled="currentPageLoading || !canUserUpdateInvitations"
            @click="openEditHostInvitationDialog(item)"
          >mdi-pencil</v-icon>
          <v-icon
            medium
            class="invitation-delete-btn"
            color="red"
            :disabled="currentPageLoading || !canUserUpdateInvitations"
            @click="openDeleteHostInvitationDialog(item)"
          >mdi-delete</v-icon>
        </div>
        <div v-else class="text-center">
          <span class="green--text">Accepted</span>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import {
  dismissAction,
  logAndExtractMessage,
  paginateTable,
} from "@/utils/utils";
import HostRoles from "@/components/custom-components/HostRoles";

export default {
  name: "HostInvitations",

  components: {
    HostRoles,
    HostInvitationDialog: () => import("../dialogs/HostInvitationDialog.vue"),
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
  },

  props: {
    pSelectedHost: Object,
  },

  data: () => ({
    localStorageTableSizeKey: "host.invitations.table.size",

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Email", value: "email", sortable: false },
      { text: "Host", value: "host", sortable: false },
      { text: "Roles", value: "roles", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("hostInvitationsModule", [
      "currentPageLoading",
      "currentPage",
      "displayHostInvitations",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
    ...mapState("userModule", ["user"]),

    canUserInviteMembers() {
      return this.user.isSuperAdmin || this.user.isUserAdminHost(this.pSelectedHost);
    },

    canUserUpdateInvitations() {
      return this.user.isSuperAdmin || this.user.isUserAdminHost(this.pSelectedHost);
    },
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(data, this.localStorageTableSizeKey, this.tableInited);

      const currentPage = {
        hostId: this.pSelectedHost.id,
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("hostInvitationsModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openEditHostInvitationDialog(invitation) {
      this.$refs.hostInvitationDialog.openDialog({ ...invitation, host: this.pSelectedHost });
    },

    openDeleteHostInvitationDialog(invitation) {
      this.$refs.deleteDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete "${invitation.email}"?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("hostInvitationsModule/deleteHostInvitation", invitation);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },

  mounted() {
    this.$emit("onMount");
  },
};
</script>
